import React from 'react';
import Img from 'gatsby-image';

export default (props) => (
    <div className="container map">
        <div className="row">
            <div className="col-12">
            { props.data &&
                <Img fixed={ props.data.localFile.childImageSharp.fixed  } alt={ props.data.alt_text} />
            }
            <div className="address">
            <h2>{props.title}</h2>
            <h4>{props.subtitle}</h4>
            </div>
            </div>
        </div>
    </div>
);