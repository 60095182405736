import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PortfolioBanner from '../components/portfolio-banner/PortfolioBanner'
import PortfolioHeader from '../components/portfolio-header/PortfolioHeader'
import ContactForm from '../components/forms/contact/ContactForm'
import ContactBranches from '../components/contact-branches/ContactBranches'
import Map from '../components/contact/Map'
import SEO from '../components/seo'

class PostTemplate extends Component {
  constructor(props) {
    super(props)
  }

  setFooterMargin() {
    document.querySelector('footer').style.marginTop = '-245px'
  }

  componentDidMount() {
    this.setFooterMargin()
  }

  render() {
    const currentPage = this.props.data.wordpressPage
    const homepage = this.props.data.wordpressSiteMetadata.home

    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
        />
        <PortfolioBanner data={currentPage.acf.banner} />
        <div className="take-up contact">
          <PortfolioHeader
            city={currentPage.content}
            title={currentPage.title}
          />
          <ContactForm form={currentPage.acf.contactCode} home={homepage} />
          <ContactBranches data={currentPage.acf.our_branches} />
          <Map
            data={currentPage.acf.map}
            title={currentPage.acf.map_title}
            subtitle={currentPage.acf.map_subtitle}
          />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        contactCode
        banner {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080, quality: 90) {
                srcSet
              }
            }
          }
          alt_text
        }
        map {
          localFile {
            childImageSharp {
              fixed(width: 1560, height: 423, quality: 90) {
                src
              }
            }
          }
          alt_text
        }
        map_title
        map_subtitle
        our_branches {
          header
          text
        }
      }
    }
    wordpressSiteMetadata {
      home
    }
  }
`
