import React, { Component } from 'react';
import ModalMessage from './../../modal-message/ModalMessage';

class ContactForm extends Component {

constructor(props) {
super(props);
this.state = {
name: '',
email: '',
company: '',
phone: '',
firstAccept: false,
secondAccept: false,
visibleModal: false,
message: '',
}

}

handleName(event) {
    this.setState({name: event.target.value});
}

handleEmail(event) {
    this.setState({email: event.target.value});
}

handlePhone(event) {
    this.setState({phone: event.target.value});
}

handleCompany(event) {
    this.setState({company: event.target.value});
}

handleMessage(event) {
    this.setState({yourMessage: event.target.value});
}

handleFirstAccept() {
    this.setState(prevState => (
        { firstAccept: !prevState.firstAccept }
    ));
}

handleSecondAccept() {
    this.setState(prevState => (
        { secondAccept: !prevState.secondAccept }
    ));
}

toogleModal() {
    this.setState( prevState => ({
        visibleModal: !prevState.visibleModal
    }))
}

submitForm(e) {
    e.preventDefault();
    let sendData = (() => {
        var data = "";
        var boundary = "blob";

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="your-name"\r\n';
        data += '\r\n';
        data += this.state.yourName + "\r\n";

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="email"\r\n';
        data += '\r\n';
        data += this.state.email + "\r\n";

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="phone"\r\n';
        data += '\r\n';
        data += this.state.phone + "\r\n";

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="company"\r\n';
        data += '\r\n';
        data += this.state.company + "\r\n";

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="message"\r\n';
        data += '\r\n';
        data += this.state.yourMessage + "\r\n";

        // close boundary
        data += "--" + boundary + "--";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.props.home + `/wp-json/contact-form-7/v1/contact-forms/${ this.props.form }/feedback`, true);
        xhr.setRequestHeader("Content-Type", 'multipart/form-data; boundary=' + boundary);
        var This = this;
        xhr.send(data);
        xhr.addEventListener('load', function() {
            let data = JSON.parse(xhr.response);
            This.setState({
                visibleModal: true,
                message: data.message,
            });
        });
    })()
}

render() {


return(
<div className="container contact-form">
    <div className="row">
        <div className="col-12">
            <form action="#" onSubmit={ this.submitForm.bind(this) }>  
            <ModalMessage message={ this.state.message } visible={ this.state.visibleModal } closeModal={
        this.toogleModal.bind(this) } /> 
                <div className="user">
                <label htmlFor="">
                    <input type="text" name="name" value={ this.state.name } onChange={ this.handleName.bind(this) } placeholder="Imię i nazwisko" autoComplete="off" required />
                </label>
                <label htmlFor="">
                    <input type="text" value={ this.state.company } onChange={ this.handleCompany.bind(this) } placeholder="Firma" name="company"/>
                </label>
                <label htmlFor="">
                    <input type="email" value={ this.state.email } onChange={ this.handleEmail.bind(this) } placeholder="Adres e-mail" name="email" />
                </label>
                <label htmlFor="">
                    <input type="text" value={ this.state.phone } onChange={ this.handlePhone.bind(this) } placeholder="Numer telefonu" name="phone" />
                </label>
                </div>
                <div className="message">
                <label htmlFor="">
                    <textarea name="message" value={ this.state.yourMessage } onChange={ this.handleMessage.bind(this) } cols="30" rows="10" placeholder="Tresć wiadomości"></textarea>
                </label>
                </div>
                <button className="btn" type="submit">Wyślij</button>
                <label className="rules" htmlFor="rule1">
                    <input type="checkbox" onClick={ this.handleFirstAccept.bind(this) } checked={ ( this.state.firstAccept ? true : false ) } id="rule1" required/>
                    <small>
Wyrażam zgodę na przetwarzanie danych osobowych  zgodnie z ustawą o ochronie danych osobowych, w związku z przesłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do prawidłowego przetworzenia wiadomości. Administratorem danych jest  MS-Meble Małyjurek Spółka z Ograniczoną Odpowiedzialnością z siedzibą przy ulicy Cieszyńskiej 82, 43-400 Cieszyn.</small>
                </label>
                <label className="rules" htmlFor="rule2">
                    <input type="checkbox" onClick={ this.handleSecondAccept.bind(this) } checked={ ( this.state.secondAccept ? true : false ) }  name="" id="rule2" />
                    <small>Wyrażam zgodę na otrzymywanie newslettera i informacji handlowych od  MS-Meble Małyjurek Spółka z Ograniczoną Odpowiedzialnością z siedzibą przy ulicy Cieszyńskiej 82, 43-400 Cieszyn. 
Zgoda jest dobrowolna. Mam prawo cofnąć zgodę w każdym czasie, mam prawo dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania.. Administratorem jest MS-Meble Małyjurek Spółka z Ograniczoną Odpowiedzialnością z siedzibą przy ulicy Cieszyńskiej 82, 43-400 Cieszyn Administrator przetwarza dane zgodnie z Polityką Prywatności.</small>
                </label>
            </form>
        </div>
    </div>
</div>

)
}
}

export default ContactForm;